import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { Params } from "@remix-run/react";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function createRequestId() {
  return Math.random().toString(36).substring(7);
}

export function createSlug(value: string) {
  const slug = value
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "");

  // Add random string to avoid conflicts
  return `${slug}-${Math.random().toString(36).substring(7)}`;
}

export function getLang(params: Params<string>) {
  const lang = params.lang ?? "en";
  if (lang !== "es" && lang !== "en") {
    throw new Response(null, {
      status: 404,
      statusText: `Not Found`,
    });
  }
  return lang;
}

export function slugify(text: string): string {
  return text
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/(^-|-$)+/g, "");
}
